import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import FoterComponent from "../Components/FoterComponent";

const Home = () => {
    const mobile = process.env.REACT_APP_MOBILE;
    const navigate = useNavigate();

    const handleHome = (event) => {
        event.preventDefault();
        navigate('/home');
    };
    const handleAbout = (event) => {
        event.preventDefault();
        navigate('/about');
    };
    const handleContact = (event) => {
        event.preventDefault();
        navigate('/contact');
    };

    //const [text, setText] = useState('https://balajionlinebetting.com/');

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast.success('Copied to clipboard!');
            })
            .catch((error) => {
                toast.error('Failed to copy text: ' + error);
            });
    };

    return (
        <>
            <ToastContainer />
            <div class="hero_area">

                <div className="img-box" style={{
                    position: 'relative', // Allows children to be absolutely positioned
                    width: '100%',
                    height: '100vh', // Adjust height as needed
                    backgroundImage: 'url(asset/images/hero-bg.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>



                    <header class="header_section">
                        <div class="header_bottom">
                            <div class="container-fluid">
                                <nav class="navbar navbar-expand-lg custom_nav-container">
                                    <a class="navbar-brand" href="#" onClick={handleHome}>
                                        <img src="asset/images/logo.png" style={{ maxWidth: 80, maxHeight: 80, mixBlendMode: "multiply" }} alt="" />
                                    </a>
                                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class=""></span>
                                    </button>

                                    <div class="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
                                        <ul class="navbar-nav  ">
                                            <li class="nav-item active">
                                                <a class="nav-link" href="#" onClick={handleHome}>Home <span class="sr-only">(current)</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#" onClick={handleAbout}> About</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#" onClick={handleContact}>Contact us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section class=" slider_section ">
                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <div class="detail-box">
                                                    <h1>
                                                        WELCOME TO <br />
                                                        <span
                                                            style={{
                                                                borderRadius: '8px', // Rounded corners for a modern look
                                                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)', // Softer outer shadow
                                                            }}
                                                        >
                                                            BALAJI ONLINE BOOK
                                                        </span>
                                                    </h1>
                                                    <p>
                                                        Discover the ultimate destination for all your online gaming thrills and excitement. At Balaji Online Book, we bring you a vast collection of games to captivate your interest and challenge your skills. Whether you're passionate about Cricket, Tennis, Football, or enjoy the thrill of popular casino games like Teen Patti, we have it all.
                                                    </p>
                                                    <div class="btn-box">
                                                        <a href="#" onClick={handleAbout} class="btn-1"> Read more </a>
                                                        <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank" className="btn-2">Sign Up Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="carousel-item ">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="detail-box">

                                                    <div style={{
                                                        display: 'flex',             // Enables flexbox
                                                        justifyContent: 'center',    // Centers content horizontally
                                                        alignItems: 'center',        // Centers content vertically
                                                        padding: '20px',             // Optional padding for smaller screens
                                                    }}>
                                                        <video
                                                            style={{
                                                                borderRadius: '10px',
                                                                boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
                                                                width: '100%',
                                                                maxWidth: '800px',    // Set a maximum width to prevent it from getting too large
                                                                height: 'auto',       // Maintain aspect ratio
                                                            }}
                                                            controls
                                                            autoPlay
                                                            loop
                                                            muted
                                                        >
                                                            <source src="asset/videos/v2.mp4" type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item ">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <div class="detail-box">

                                                    <div style={{
                                                        display: 'flex',             // Enables flexbox
                                                        justifyContent: 'center',    // Centers content horizontally
                                                        alignItems: 'center',        // Centers content vertically
                                                        padding: '20px',             // Optional padding for smaller screens
                                                    }}>
                                                        <video
                                                            style={{
                                                                borderRadius: '10px',
                                                                boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
                                                                width: '100%',
                                                                maxWidth: '800px',    // Set a maximum width to prevent it from getting too large
                                                                height: 'auto',       // Maintain aspect ratio
                                                            }}
                                                            controls
                                                            autoPlay
                                                            loop
                                                            muted
                                                        >
                                                            <source src="asset/videos/v1.mp4" type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div class="container idicator_container">
                                <ol class="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                    {/* <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
                                </ol>
                            </div>
                        </div>
                    </section>
                </div></div>

            <section class="service_section layout_padding">
                <div class="container">
                    <div class="heading_container heading_center">
                        <h2>
                            Features and Sites
                        </h2>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="box">
                                <div class="img-box">
                                    <img src="asset/images/nic7777.png" style={{ maxWidth: 200, maxHeight: 80, mixBlendMode: "multiply" }} alt="" />
                                </div>
                                <div class="detail-box">
                                    <a href="https://nice7777.fun/" class="feature-link1" target="_blank">
                                        nice7777.fun
                                    </a>
                                    <span class="feature-link2" onClick={() => copyToClipboard('SA18399')}>
                                        User 🆔 :- SA18399
                                    </span>
                                    <span class="feature-link2" onClick={() => copyToClipboard('Balaji999')}>
                                        Pass 📟:- Balaji999
                                    </span>
                                    {/* <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank" class="feature-link2">
                                        Get Demo ID
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="box">
                                <div class="img-box">
                                    <img src="asset/images/nice9999.png" style={{ maxWidth: 200, maxHeight: 80, mixBlendMode: "multiply" }} alt="" />
                                </div>
                                <div class="detail-box">
                                    <a href="http://nice9999.fun/" class="feature-link1" target="_blank">
                                        nice9999.fun
                                    </a>
                                    <span class="feature-link2" onClick={() => copyToClipboard('BS27426')}>
                                        User 🆔 :- BS27426
                                    </span>
                                    <span class="feature-link2" onClick={() => copyToClipboard('Balaji999')}>
                                        Pass 📟:- Balaji999
                                    </span>
                                    {/* <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank" class="feature-link2">
                                        Get Demo ID
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="box">
                                <div class="img-box">
                                    <img src="asset/images/sona77.png" style={{ maxWidth: 200, maxHeight: 80, mixBlendMode: "multiply" }} alt="" />
                                </div>
                                <div class="detail-box">
                                    <a href="http://Sona77exch.com" class="feature-link1" target="_blank">
                                        Sona77exch.com
                                    </a>
                                    <span class="feature-link2" onClick={() => copyToClipboard('balaji3610')}>
                                        User 🆔 :- balaji3610
                                    </span>
                                    <span class="feature-link2" onClick={() => copyToClipboard('Balaji999')}>
                                        Pass 📟:- Balaji999
                                    </span>
                                    {/* <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank" class="feature-link2">
                                        Get Demo ID
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="box">
                                <div class="img-box">
                                    <img src="https://images.staticcontent.io/nic/uimg/brand_logo.svg" style={{ maxWidth: 200, maxHeight: 80, mixBlendMode: "multiply" }} alt="" />
                                </div>
                                <div class="detail-box">
                                    <a href="https://www.nice77.fun/" class="feature-link1" target="_blank">
                                        nice77.fun
                                    </a>
                                    <span class="feature-link2" onClick={() => copyToClipboard('balaji5980')}>
                                        User 🆔 :- balaji5980
                                    </span>
                                    <span class="feature-link2" onClick={() => copyToClipboard('Balaji999')}>
                                        Pass 📟:- Balaji999
                                    </span>
                                    {/* <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank" class="feature-link2">
                                        Get Demo ID
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="box ">
                                <div class="img-box">
                                    <img src="https://d1arlbwbznybm5.cloudfront.net/v3/static/themes/lordsexch.com/front/logo-login.png" style={{ maxWidth: 200, maxHeight: 80, backgroundColor: "grey" }} alt="" />
                                </div>
                                <div class="detail-box">
                                    <a href="http://Lordsexch.com" class="feature-link1" target="_blank">
                                        Lordsexch.com
                                    </a>
                                    <span class="feature-link2" onClick={() => copyToClipboard('Balaji129')}>
                                        User 🆔 :- Balaji129
                                    </span>
                                    <span class="feature-link2" onClick={() => copyToClipboard('Balaji999')}>
                                        Pass 📟:- Balaji999
                                    </span>
                                    {/* <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank" class="feature-link2">
                                        Get Demo ID
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="box">
                                <div class="img-box">
                                    <img src="https://sitethemedata.com/sitethemes/luckybook.co/front/logo.png" style={{ maxWidth: 200, maxHeight: 80, mixBlendMode: "multiply" }} alt="" />
                                </div>
                                <div class="detail-box">
                                    <a href="http://Luckybook.co" class="feature-link1" target="_blank">
                                        Luckybook.co
                                    </a>
                                    <span class="feature-link2" onClick={() => copyToClipboard('Balaji226')}>
                                        User 🆔 :- Balaji226
                                    </span>
                                    <span class="feature-link2" onClick={() => copyToClipboard('Balaji999')}>
                                        Pass 📟:- Balaji999
                                    </span>
                                    {/* <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank" class="feature-link2">
                                        Get Demo ID
                                    </a> */}
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </section>

            <section class="client_section layout_padding">
                <div class="container">
                    <div class="heading_container heading_center">
                        <h2>
                            The More You Play, The More You Save
                        </h2>
                    </div>
                    <div>
                        <div>
                            <br />
                            <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank" class="feature-link3">
                                Click to receive your ID
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <FoterComponent />

        </>
    )
};

export default Home;
