import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FoterComponent from "../Components/FoterComponent";

const ContactPage = (props) => {
    const mobile = process.env.REACT_APP_MOBILE;
    const navigate = useNavigate();

    const handleHome = (event) => {
        event.preventDefault();
        navigate('/home');
    };
    const handleAbout = (event) => {
        event.preventDefault();
        navigate('/about');
    };
    const handleContact = (event) => {
        event.preventDefault();
        navigate('/contact');
    };

    //----Form Submit------

    const ContactForm = () => {
        const [formData, setFormData] = useState({
            fullName: '',
            email: '',
            mobile: '',
            message: ''
        });

        const [errors, setErrors] = useState({
            fullName: '',
            email: '',
            mobile: '',
            message: ''
        });

        const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission

        const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            setErrors({ ...errors, [e.target.name]: '' }); // Clear the error message when user starts typing
        };

        const handleSubmit = async (e) => {
            e.preventDefault();

            if (!validateForm()) {
                return;
            }

            setIsSubmitting(true); // Set submitting state to true

            try {
                const response = await fetch('https://balaji-online-backend.onrender.com/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log('Email sent successfully', data);
                alert('Email sent successfully!');
                setFormData({
                    fullName: '',
                    email: '',
                    mobile: '',
                    message: ''
                });
            } catch (error) {
                console.error('Error sending email:', error);
                alert('Failed to send email');
            } finally {
                setIsSubmitting(false); // Set submitting state back to false
            }
        };

        const validateForm = () => {
            let isValid = true;
            const newErrors = {
                fullName: '',
                email: '',
                mobile: '',
                message: ''
            };

            if (formData.fullName.trim() === '') {
                newErrors.fullName = 'Full Name is required';
                isValid = false;
            }

            if (formData.email.trim() === '') {
                newErrors.email = 'Email is required';
                isValid = false;
            } else if (!isValidEmail(formData.email)) {
                newErrors.email = 'Email is invalid';
                isValid = false;
            }

            if (formData.mobile.trim() === '') {
                newErrors.mobile = 'Mobile Number is required';
                isValid = false;
            }

            if (formData.message.trim() === '') {
                newErrors.message = 'Message is required';
                isValid = false;
            }

            setErrors(newErrors);
            return isValid;
        };

        const isValidEmail = (email) => {
            // Basic email format validation
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        };

        return (
            <>
                <div className="sub_page">
                    <div className="hero_area">
                        <div className="hero_bg_box">
                            <div className="img-box">
                                <img src="asset/images/hero-bg.jpg" style={{ maxWidth: 100 }} alt="" />
                            </div>
                        </div>

                        <header className="header_section">

                            <div className="header_bottom">
                                <div className="container-fluid">
                                    <nav className="navbar navbar-expand-lg custom_nav-container">
                                        <a className="navbar-brand" href="#" onClick={handleHome}>
                                            <img src="asset/images/logo.png" style={{ maxWidth: 80, maxHeight: 80, mixBlendMode: "multiply" }} alt="" />
                                        </a>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className=""></span>
                                        </button>

                                        <div className="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
                                            <ul className="navbar-nav  ">
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="#" onClick={handleHome}>Home <span className="sr-only">(current)</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#" onClick={handleAbout}> About</a>
                                                </li>

                                                <li className="nav-item active">
                                                    <a className="nav-link" href="#" onClick={handleContact}>Contact us</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </header>
                    </div>

                    <section className="contact_section layout_padding">
                        <div className="contact_bg_box">
                            <div className="img-box">
                                <img src="asset/images/contact-bg.jpg" alt="" />
                            </div>
                        </div>
                        <div className="container">
                            <div className="heading_container heading_center">
                                <h2>
                                    Get In touch
                                </h2>
                            </div>
                            <div className="">
                                <div className="row">
                                    <div className="col-md-7 mx-auto">
                                        <form onSubmit={handleSubmit}>
                                            <div className="contact_form-container">
                                                <div>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="fullName"
                                                            placeholder="Full Name"
                                                            value={formData.fullName}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.fullName && <span className="error">{errors.fullName}</span>}
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            placeholder="Email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.email && <span className="error">{errors.email}</span>}
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            maxLength={10}
                                                            placeholder="Mobile Number"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.mobile && <span className="error">{errors.mobile}</span>}
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="text"
                                                            name="message"
                                                            placeholder="Message"
                                                            className="message_input"
                                                            value={formData.message}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.message && <span className="error">{errors.message}</span>}
                                                    </div>
                                                    <div className="btn-box">
                                                        <button type="submit" disabled={isSubmitting}>
                                                            {isSubmitting ? 'Sending...' : 'Send'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <FoterComponent />

                </div>
            </>
        )
    };

    return <ContactForm />;
};

export default ContactPage;
