import React from "react"

const NotFoundPage = (props) => {
  return (
    <div>
      <h1>Not Found</h1>
    </div>
  )
};

export default NotFoundPage;
