import React from "react";
import { useNavigate } from 'react-router-dom';

const FoterComponent = () => {
    const mobile = process.env.REACT_APP_MOBILE;
    const navigate = useNavigate();

    const handleHome = (event) => {
        event.preventDefault();
        navigate('/home');
    };
    const handleAbout = (event) => {
        event.preventDefault();
        navigate('/about');
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handleTerms = (event) => {
        event.preventDefault();
        navigate('/terms');
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <section class="info_section ">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="info_logo">
                                <a class="navbar-brand" href="index.html">
                                    <span>
                                        Balaji Online Book
                                    </span>
                                </a>
                                <img src="asset/images/logo.png" style={{ maxWidth: 80, maxHeight: 80 }} class="img-fluid" alt="Image" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info_links">
                                <h5>
                                    Categories
                                </h5>
                                <ul>
                                    <li>
                                        <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank">
                                            Sports
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank">
                                            Casino
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank">
                                            Arcade
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info_info">
                                <h5>
                                    Contact Us
                                </h5>
                            </div>
                            <div class="info_contact">
                                <a href="" class="">
                                    <span>
                                        For any Queries, Emergencies, Feedbacks or Complaints, you can get in touch with 24x7 Balaji Online Book Customer Care
                                    </span>
                                </a>
                                {/* <a href="" class="">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    <span>
                                        Call : +918411828384
                                    </span>
                                </a> */}
                                <a href="mailto:balaji.coustomercare@gmail.com" class="">
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                    <span>
                                        balaji.coustomercare@gmail.com
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info_info">
                                <h5>
                                    Policies
                                </h5>
                            </div>
                            <div class="info_contact">
                                <a href="" onClick={handleTerms}>Terms of Use</a>
                                <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank">Refund Policy</a>
                                <a href="" onClick={handleAbout}>Privacy Policy</a>
                                <a href="" onClick={handleAbout}>Cookie Policy</a>
                                <a href="" onClick={handleAbout}>FAQs</a>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="info_form ">
                                <h5>
                                    Payment Options
                                </h5>
                                <div class="social_box">
                                    <a>
                                        <i class="fa fa-cc-visa fa-2x" aria-hidden="true"></i>
                                    </a>
                                    <a>
                                        <i class="fa fa-cc-mastercard fa-2x" aria-hidden="true"></i>
                                    </a>
                                    <a>
                                        <i class="fa fa-paypal fa-2x" aria-hidden="true"></i>
                                    </a>
                                    <a>
                                        <img src="asset/images/phonepay.png" style={{ width: 55, height: 42, filter: 'grayscale(100%)' }} class="img-fluid" alt="Image" />
                                    </a>
                                    <a>
                                        <img src="asset/images/paytm.png" style={{ width: 55, height: 42, filter: 'grayscale(100%)' }} class="img-fluid" alt="Image" />
                                    </a>
                                    <a>
                                        <img src="asset/images/gpay.png" style={{ width: 55, height: 42, filter: 'grayscale(100%)' }} class="img-fluid" alt="Image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info_form ">
                                <h5>
                                    Follow Us
                                </h5>
                                <div class="social_box">
                                    <a href="">
                                        <i class="fa fa-facebook" aria-hidden="true"></i>
                                    </a>
                                    <a href="">
                                        <i class="fa fa-twitter" aria-hidden="true"></i>
                                    </a>
                                    <a href="">
                                        <i class="fa fa-youtube" aria-hidden="true"></i>
                                    </a>
                                    <a href="">
                                        <i class="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <footer class="container-fluid footer_section">
                <p>
                    &copy; <span id="currentYear"></span> All Rights Reserved
                    <a href="https://html.design/"> Balaji Online Book</a>
                </p>
            </footer>
        </>
    )
};

export default FoterComponent;
