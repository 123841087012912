import React from "react";
import { useNavigate } from 'react-router-dom';
import FoterComponent from "../Components/FoterComponent";

const TermsPage = () => {
    const mobile = process.env.REACT_APP_MOBILE;
    const navigate = useNavigate();

    const handleHome = (event) => {
        event.preventDefault();
        navigate('/home');
    };
    const handleAbout = (event) => {
        event.preventDefault();
        navigate('/about');
    };
    const handleContact = (event) => {
        event.preventDefault();
        navigate('/contact');
    };

    return (
        <div class="sub_page">
            <div class="hero_area">
                <div class="hero_bg_box">
                    <div class="img-box">
                        <img src="asset/images/hero-bg.jpg" style={{ maxWidth: 100 }} alt="" />
                    </div>
                </div>

                <header class="header_section">
                    <div class="header_bottom">
                        <div class="container-fluid">
                            <nav class="navbar navbar-expand-lg custom_nav-container">
                                <a class="navbar-brand" href="#" onClick={handleHome}>
                                    <img src="asset/images/logo.png" style={{ maxWidth: 80, maxHeight: 80, mixBlendMode: "multiply" }} alt="" />
                                </a>
                                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class=""></span>
                                </button>

                                <div class="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
                                    <ul class="navbar-nav  ">
                                        <li class="nav-item ">
                                            <a class="nav-link" href="#" onClick={handleHome}>Home <span class="sr-only">(current)</span></a>
                                        </li>
                                        <li class="nav-item active">
                                            <a class="nav-link" href="#" onClick={handleAbout}> About</a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" href="#" onClick={handleContact}>Contact us</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
            </div>

            <section class="about_section layout_padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 px-0">
                            <div class="img_container">
                                <div class="img-box">
                                    <img src="asset/images/about-img.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 px-0">
                            <div class="detail-box">
                                <div class="heading_container ">
                                    <h2>
                                        Terms & Conditions
                                    </h2>
                                </div>
                                <p>
                                    Welcome to Balaji Online Book, your premier online gaming platform offering a variety of exciting games including cricket, tennis, football, casino, teen patti, dragon, and tiger. By accessing and using our website, you agree to abide by our Terms & Conditions, which govern the use of our platform and define the relationship between Balaji Online Book and its users.
                                </p>
                                <div class="btn-box">
                                    <b>Please be aware that the Terms & Conditions of Balaji Online Book are unique to our platform and may differ from those of other online gaming platforms. We encourage you to carefully review our Terms & Conditions before using our platform.</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FoterComponent />


        </div>
    )
};

export default TermsPage;
