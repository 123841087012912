import React from "react";
import { useNavigate } from 'react-router-dom';
import FoterComponent from "../Components/FoterComponent";

const AboutPage = (props) => {
    const mobile = process.env.REACT_APP_MOBILE;
    const navigate = useNavigate();

    const handleHome = (event) => {
        event.preventDefault();
        navigate('/home');
    };
    const handleAbout = (event) => {
        event.preventDefault();
        navigate('/about');
    };
    const handleContact = (event) => {
        event.preventDefault();
        navigate('/contact');
    };
    return (
        <>
            <div class="sub_page">
                <div class="hero_area">
                    <div class="hero_bg_box">
                        <div class="img-box">
                        <img src="asset/images/hero-bg.jpg" style={{ maxWidth: 100 }} alt="" />
                        </div>
                    </div>

                    <header class="header_section">
                        {/* <div class="header_top">
                            <div class="container-fluid">
                                <div class="contact_link-container">
                                    <a href="" class="contact_link1">
                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        <span>
                                            Lorem ipsum dolor sit amet,
                                        </span>
                                    </a>
                                    <a href="" class="contact_link2">
                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                        <span>
                                            Call : +01 1234567890
                                        </span>
                                    </a>
                                    <a href="" class="contact_link3">
                                        <i class="fa fa-envelope" aria-hidden="true"></i>
                                        <span>
                                            demo@gmail.com
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        <div class="header_bottom">
                            <div class="container-fluid">
                                <nav class="navbar navbar-expand-lg custom_nav-container">
                                    <a class="navbar-brand" href="#" onClick={handleHome}>
                                    <img src="asset/images/logo.png" style={{ maxWidth: 80, maxHeight: 80,mixBlendMode:"multiply" }} alt="" />
                                    </a>
                                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class=""></span>
                                    </button>

                                    <div class="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
                                        <ul class="navbar-nav  ">
                                            <li class="nav-item ">
                                                <a class="nav-link" href="#" onClick={handleHome}>Home <span class="sr-only">(current)</span></a>
                                            </li>
                                            <li class="nav-item active">
                                                <a class="nav-link" href="#" onClick={handleAbout}> About</a>
                                            </li>
                                            
                                            <li class="nav-item">
                                                <a class="nav-link" href="#" onClick={handleContact}>Contact us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </header>
                </div>

                <section class="about_section layout_padding">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 px-0">
                                <div class="img_container">
                                    <div class="img-box">
                                        <img src="asset/images/about-img.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 px-0">
                                <div class="detail-box">
                                    <div class="heading_container ">
                                        <h2>
                                            About Balaji Online Book
                                        </h2>
                                    </div>
                                    <p>
                                    Welcome to Balaji Online Book! We are an online betting platform that offers a wide range of exciting games including tennis, cricket, football, casino, teen patti, dragon tiger, and more. Our goal is to provide an exhilarating and secure gaming experience for our users. With a team of dedicated professionals, we are committed to delivering top-notch betting services and ensuring customer satisfaction. Join us today and experience the thrill of online betting!
                                    </p>
                                    <div class="btn-box">
                                        <a href={`https://api.whatsapp.com/send?phone=${mobile}&text=Demo%20ID`} target="_blank">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

               <FoterComponent/>


            </div>
        </>
    )
};

export default AboutPage;
